import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Layout } from "../../Components/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "js-cookie";
import axios from "axios";

import Popup from "../../Components/Popup";
import { Button } from "../../Components";
import configSettings from "../../config";
import useSession from "../../Hooks/get-session";

const Folder = () => {
    const { data: session, isLoading: sessionLoading } = useSession();

    const [tags, setTags] = useState([]);
    const [folder, setFolder] = useState();

    const [editFolderModal, setEditFolderModal] = useState(false);

    const [isPopup, setIsPopup] = useState(false);
    const [popupDetail, setPopupDetail] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const getFolder = async () => {
        const folderId = getFolderId();

        // setIsSpinner(true)
        try {
            const result = await axios.get(
                configSettings.serverUrl + `/getFolder/${folderId}`,
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            const response = result.data;

            setFolder(response.data);
        } catch (error) {
            //    setIsSpinner(false)
        }
    };

    const getAllTags = async () => {
        try {
            // setIsSpinner(true);
            const result = await axios.get(
                configSettings.serverUrl + "/getTags",

                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            const response = result.data;

            setTags(response.data);
        } catch (error) {
            console.log(error);
            // setIsSpinner(false);
        }
    };

    const updateFolder = async () => {
        setIsLoading(true);
        try {
            await axios.put(
                configSettings.serverUrl + `/updateFolder/${folder?._id}`,
                {
                    name: folder?.name,
                    tag: folder?.tag,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            setIsLoading(false);

            setEditFolderModal(false);

            setPopupDetail({
                type: "Success",
                text: "Folder has been successfully updated",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getFolder();
        } catch (error) {
            setIsLoading(false);
            if (error.response.status === 400) {
                setPopupDetail({
                    type: "Warning",
                    text: error.response.data.message,
                });

                setIsPopup(true);

                setTimeout(function () {
                    setIsPopup(false);
                }, 4000);
            }
        }
    };

    const deleteFolder = async () => {
        try {
            // setIsSpinner(true);
            await axios.delete(
                configSettings.serverUrl + `/deleteFolder/${folder?._id}`,
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            setPopupDetail({
                type: "Success",
                text: "Folder has been successfully deleted",
            });

            setIsPopup(true);

            setTimeout(function () {
                navigate("/posters");
            }, 2000);
        } catch (error) {
            // setIsSpinner(false);
        }
    };

    const deletePoster = async (poster) => {
        const folderId = getFolderId();

        try {
            // setIsSpinner(true);
            await axios.put(
                configSettings.serverUrl + `/deletePoster/${folderId}`,
                {
                    poster: poster.posterPath,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            setPopupDetail({
                type: "Success",
                text: "Poster has been successfully deleted",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 2000);

            getFolder();
        } catch (error) {
            // setIsSpinner(false);
        }
    };

    const downloadPoster = async (posterPath) => {
        try {
            // setIsSpinner(true);
            const response = await axios.post(
                configSettings.serverUrl + `/downloadPoster`,
                {
                    posterPath: posterPath,
                },
                {
                    responseType: "arraybuffer",
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false)

            // Convert the response data to a blob
            const blob = new Blob([response.data], { type: "image/png" });

            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "poster.png";

            // Append the link to the document and trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up: remove the link from the document
            document.body.removeChild(a);
        } catch (error) {
            // setIsSpinner(false)
        }
    };

    const duplicateFolder = async () => {
        const folderId = getFolderId();

        try {
            // setIsSpinner(true);
            await axios.post(
                configSettings.serverUrl + `/duplicateFolder/${folderId}`,
                {},
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false)

            setPopupDetail({
                type: "Success",
                text: "Folder has been successfully duplicated",
            });

            setIsPopup(true);

            setTimeout(function () {
                navigate("/posters");
            }, 2000);
        } catch (error) {
            // setIsSpinner(false)
        }
    };

    const duplicatePoster = async (posterPath) => {
        const folderId = getFolderId();

        try {
            // setIsSpinner(true);
            await axios.post(
                configSettings.serverUrl + `/duplicatePoster`,
                {
                    folderId: folderId,
                    posterPath: posterPath,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false)

            setPopupDetail({
                type: "Success",
                text: "Poster has been successfully duplicated",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getFolder();
        } catch (error) {
            // setIsSpinner(false)
        }
    };

    const getFolderId = () => {
        const currentUrl = window.location.href;
        const parsedUrl = new URL(currentUrl);
        const pathname = parsedUrl.pathname;
        const pathSegments = pathname.split("/");

        const folderId = pathSegments[pathSegments.length - 1];

        return folderId;
    };

    useEffect(() => {
        getFolder();
        getAllTags();
    }, []);

    useEffect(() => {
        if (!session && !sessionLoading) {
            navigate("/login");
        }
    }, [session, sessionLoading]);

    return (
        <>
            <Layout>
                <div className="flex items-center bg-white p-4 pb-0">
                    <ArrowBackIcon
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                            navigate("/posters");
                        }}
                    />

                    <p>Back</p>
                </div>
                <div className="flex items-center justify-between bg-white p-4">
                    <div className="flex items-center">
                        <p className="text-xl font-medium tracking-wider mr-3">
                            {folder?.name}
                        </p>

                        <div className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3 mr-3">
                            <DescriptionIcon className="!text-lg text-[#4B81D7] mr-1" />
                            <p className="text-sm text-[#4B81D7] ml-1">
                                {folder?.posters.length}
                            </p>
                        </div>

                        <div className="bg-[#e7f9ef] rounded-md p-2 px-3">
                            <p className="text-sm text-[#1db304]">
                                {folder?.tag}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div
                            className="flex items-center bg-[#4b82d7] text-white py-2 px-4 mr-3 rounded-lg cursor-pointer"
                            onClick={() => {
                                setEditFolderModal(true);
                            }}
                        >
                            <EditIcon className="mr-2" />
                            <p>Edit Folder</p>
                        </div>

                        <div
                            className="flex items-center bg-[#4b82d7] text-white py-2 px-4 mr-3 rounded-lg cursor-pointer"
                            onClick={() => {
                                duplicateFolder();
                            }}
                        >
                            <FileCopyIcon className="mr-2" />
                            <p>Duplicate Folder</p>
                        </div>

                        <div
                            className="flex items-center bg-[#f43b3b] text-white py-2 px-4 rounded-lg cursor-pointer"
                            onClick={deleteFolder}
                        >
                            <DeleteOutlineIcon className="mr-2" />
                            <p>Delete Folder</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap w-full bg-[#eeeeee] p-5">
                    {folder?.posters?.map((poster, index) => {
                        return (
                            <div
                                key={index}
                                className="relative flex items-center flex-col justify-center bg-white p-4 w-56 border border-gray-400 rounded-lg mr-5 mb-5 overflow-hidden"
                            >
                                <img
                                    src={poster.posterPath}
                                    alt=""
                                    className="w-40 h-52 border border-gray-800"
                                />
                                <p className="text-lg font-semibold tracking-wider text-center mt-2 mb-2">
                                    {poster.posterType}
                                </p>

                                <div className="flex items-center justify-center">
                                    <div
                                        className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3 mr-2 cursor-pointer"
                                        onClick={() => {
                                            window.open(
                                                poster.posterPath,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        <RemoveRedEyeIcon className="!text-lg text-[#4B81D7]" />
                                    </div>

                                    {/* <div
                                        className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3 mr-2 cursor-pointer"
                                        onClick={() => {
                                            duplicatePoster();
                                        }}
                                    >
                                        <FileCopyIcon className="!text-lg text-[#4B81D7]" />
                                    </div> */}

                                    <div
                                        className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3 mr-2 cursor-pointer"
                                        onClick={() => {
                                            downloadPoster(poster.posterPath);
                                        }}
                                    >
                                        <DownloadIcon className="!text-lg text-[#4B81D7]" />
                                    </div>

                                    <div
                                        className="flex items-center justify-between bg-[#ffd3d3] rounded-md p-2 px-3 cursor-pointer"
                                        onClick={() => {
                                            deletePoster(poster);
                                        }}
                                    >
                                        <DeleteOutlineIcon className="!text-lg text-[#f43b3b]" />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* edit folder modal */}

                <Transition.Root show={editFolderModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50"
                        onClose={() => {
                            setEditFolderModal(false);
                        }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-xl font-semibold tracking-wider mb-3">
                                                Edit Folder
                                            </p>

                                            <CloseIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setEditFolderModal(false);
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <p className="mb-1 text-sm">
                                                Folder Name
                                            </p>

                                            <input
                                                name="name"
                                                type="text"
                                                placeholder="Poster Name"
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                value={folder?.name}
                                                onChange={(e) => {
                                                    setFolder({
                                                        ...folder,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col">
                                            <p className="mb-1 text-sm">Tag</p>

                                            <select
                                                name="tag"
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                value={folder?.tag}
                                                onChange={(e) => {
                                                    setFolder({
                                                        ...folder,
                                                        tag: e.target.value,
                                                    });
                                                }}
                                            >
                                                <option value="">
                                                    Select Tag
                                                </option>

                                                {tags?.map((tag, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={tag.name}
                                                        >
                                                            {tag.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <Button
                                            title="Save"
                                            className="mt-4 inline-flex w-full justify-center rounded-md bg-[#4b82d7] px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none"
                                            loading={isLoading}
                                            onClick={updateFolder}
                                        />
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </Layout>

            <Popup
                type={popupDetail?.type}
                text={popupDetail?.text}
                isPopup={isPopup}
            />
        </>
    );
};

export default Folder;
