import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Layout } from "../../Components/Layout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Cookies from "js-cookie";
import axios from "axios";

import Popup from "../../Components/Popup";
import Spinner from "../../Components/Spinner";
import configSettings from "../../config";
import useSession from "../../Hooks/get-session";

const Tags = () => {
    const { data: session, isLoading: sessionLoading } = useSession();

    const [tags, setTags] = useState([]);

    const [tag, setTag] = useState("");
    const [selectedTag, setSelectedTag] = useState({ id: "", name: "" });

    const [addTagModal, setAddTagModal] = useState(false);
    const [editTagModal, setEditTagModal] = useState(false);

    const [isPopup, setIsPopup] = useState(false);
    const [popupDetail, setPopupDetail] = useState();
    const [isSpinner, setIsSpinner] = useState(false);

    const navigate = useNavigate();

    const addTag = async () => {
        try {
            setIsSpinner(true);
            await axios.post(
                configSettings.serverUrl + "/createTag",
                {
                    tag: tag,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            setTag("");

            setAddTagModal(false);

            setIsSpinner(false);

            setPopupDetail({
                type: "Success",
                text: "Tag has been successfully added",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getAllTags();
        } catch (error) {
            setIsSpinner(false);
            if (error.response.status === 400) {
                setPopupDetail({
                    type: "Warning",
                    text: error.response.data.error,
                });

                setIsPopup(true);

                setTimeout(function () {
                    setIsPopup(false);
                }, 4000);
            }
        }
    };

    const getAllTags = async () => {
        try {
            // setIsSpinner(true);
            const result = await axios.get(
                configSettings.serverUrl + "/getTags",

                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            const response = result.data;

            setTags(response.data);
        } catch (error) {
            console.log(error);
            // setIsSpinner(false);
        }
    };

    const deleteTag = async (tagId) => {
        try {
            setIsSpinner(true);
            await axios.delete(
                configSettings.serverUrl + `/deleteTag/${tagId}`,
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            setSelectedTag({ id: "", name: "" });

            setEditTagModal(false);

            setIsSpinner(false);

            setPopupDetail({
                type: "Success",
                text: "Tag has been successfully deleted",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getAllTags();
        } catch (error) {
            setIsSpinner(false);
        }
    };

    const updateTag = async () => {
        setIsSpinner(true);
        try {
            await axios.put(
                configSettings.serverUrl + `/updateTag/${selectedTag?.id}`,
                {
                    name: selectedTag?.name,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            setIsSpinner(false);

            setSelectedTag({ id: "", name: "" });

            setEditTagModal(false);

            setPopupDetail({
                type: "Success",
                text: "Tag has been successfully updated",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getAllTags();
        } catch (error) {
            setIsSpinner(false);
            if (error.response.status === 400) {
                setPopupDetail({
                    type: "Warning",
                    text: error.response.data.message,
                });

                setIsPopup(true);

                setTimeout(function () {
                    setIsPopup(false);
                }, 4000);
            }
        }
    };

    useEffect(() => {
        getAllTags();
    }, []);

    useEffect(() => {
        if (!session && !sessionLoading) {
            navigate("/login");
        }
    }, [session, sessionLoading]);

    return (
        <>
            <Layout>
                <div className="flex items-center justify-between bg-white p-4">
                    <div className="flex items-center">
                        <p className="text-xl font-medium tracking-wider mr-3">
                            Tags
                        </p>
                        <div className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3">
                            <LocalOfferIcon className="!text-lg text-[#4B81D7] mr-1" />
                            <p className="text-sm text-[#4B81D7] ml-1">
                                {tags.length}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div
                            className="flex items-center bg-[#4b82d7] text-white py-2 px-4 rounded-lg cursor-pointer"
                            onClick={() => {
                                setAddTagModal(true);
                            }}
                        >
                            <AddCircleIcon className="mr-2" />
                            <p>Add Tag</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-white rounded-lg m-5 p-2">
                    {tags?.map((tag, index) => {
                        return (
                            <div
                                key={index}
                                className="flex items-center justify-between border border-gray-300 rounded-lg p-2"
                            >
                                <div className="bg-[#e7f9ef] rounded-md p-2 px-3">
                                    <p className="text-sm text-[#1db304]">
                                        {tag.name}
                                    </p>
                                </div>

                                <div className="flex items-center">
                                    <div
                                        className="flex items-center bg-[#4b82d7] text-white mr-2 py-2 px-4 rounded-lg cursor-pointer"
                                        onClick={() => {
                                            setSelectedTag({
                                                id: tag._id,
                                                name: tag.name,
                                            });
                                            setEditTagModal(true);
                                        }}
                                    >
                                        <EditIcon className="mr-2 !text-xl" />
                                        <p className="text-sm">Edit</p>
                                    </div>

                                    <div
                                        className="flex items-center bg-[#f43b3b] text-white py-2 px-4 rounded-lg cursor-pointer"
                                        onClick={() => {
                                            setSelectedTag({
                                                id: tag._id,
                                                name: tag.name,
                                            });
                                            deleteTag(tag._id);
                                        }}
                                    >
                                        <DeleteOutlineIcon className="mr-2 !text-xl" />
                                        <p className="text-sm">Delete</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* add tag modal */}

                <Transition.Root show={addTagModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50"
                        onClose={() => {
                            setAddTagModal(false);
                        }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-xl font-semibold tracking-wider mb-3">
                                                Add Tag
                                            </p>

                                            <CloseIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setAddTagModal(false);
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col">
                                            <p className="mb-1 text-sm">
                                                Tag Name
                                            </p>

                                            <input
                                                name="tag"
                                                type="text"
                                                placeholder="Tag Name"
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                value={tag}
                                                onChange={(e) => {
                                                    setTag(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            className="mt-4 inline-flex w-full justify-center rounded-md bg-[#4b82d7] px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none"
                                            onClick={addTag}
                                        >
                                            Save
                                        </button>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* edit tag modal */}

                <Transition.Root show={editTagModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50"
                        onClose={() => {
                            setEditTagModal(false);
                        }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-xl font-semibold tracking-wider mb-3">
                                                Edit Tag
                                            </p>

                                            <CloseIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setEditTagModal(false);
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col">
                                            <p className="mb-1 text-sm">
                                                Tag Name
                                            </p>

                                            <input
                                                type="text"
                                                name="tag"
                                                placeholder="Tag Name"
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                value={selectedTag?.name}
                                                onChange={(e) => {
                                                    setSelectedTag({
                                                        ...selectedTag,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            className="mt-4 inline-flex w-full justify-center rounded-md bg-[#4b82d7] px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none"
                                            onClick={updateTag}
                                        >
                                            Save
                                        </button>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </Layout>

            {isSpinner && (
                <>
                    <Spinner />
                </>
            )}

            <Popup
                type={popupDetail?.type}
                text={popupDetail?.text}
                isPopup={isPopup}
            />
        </>
    );
};

export default Tags;
