import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Layout } from "../../Components/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";

import Popup from "../../Components/Popup";
import { Button } from "../../Components";
import configSettings from "../../config";
import { useNavigate } from "react-router-dom";
import useSession from "../../Hooks/get-session";

const Posters = () => {
    const { data: session, isLoading: sessionLoading } = useSession();

    const [folders, setFolders] = useState([]);
    const [folder, setFolder] = useState({ name: "", tag: "" });
    const [tags, setTags] = useState([]);

    const [addFolderModal, setAddFolderModal] = useState(false);

    const [isPopup, setIsPopup] = useState(false);
    const [popupDetail, setPopupDetail] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const getAllTags = async () => {
        try {
            // setIsSpinner(true);
            const result = await axios.get(
                configSettings.serverUrl + "/getTags",

                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            const response = result.data;

            setTags(response.data);
        } catch (error) {
            console.log(error);
            // setIsSpinner(false);
        }
    };

    const getAllFolders = async () => {
        try {
            // setIsSpinner(true);
            const result = await axios.get(
                configSettings.serverUrl + "/getFolders",
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            // setIsSpinner(false);

            const response = result.data;

            setFolders(response.data);
        } catch (error) {
            console.log(error);
            // setIsSpinner(false);
        }
    };

    const createFolder = async () => {
        try {
            setIsLoading(true);
            await axios.post(
                configSettings.serverUrl + "/createFolder",
                {
                    name: folder?.name,
                    tag: folder?.tag,
                },
                {
                    headers: {
                        "access-token": Cookies.get("access-token"),
                    },
                }
            );

            setFolder({ name: "", tag: "" });

            setAddFolderModal(false);

            setIsLoading(false);

            setPopupDetail({
                type: "Success",
                text: "Folder has been successfully created",
            });

            setIsPopup(true);

            setTimeout(function () {
                setIsPopup(false);
            }, 4000);

            getAllFolders();
        } catch (error) {
            setIsLoading(false);
            if (error.response.status === 400) {
                setPopupDetail({
                    type: "Warning",
                    text: error.response.data.message,
                });

                setIsPopup(true);

                setTimeout(function () {
                    setIsPopup(false);
                }, 4000);
            }
        }
    };

    const getPostersLength = () => {
        let length = 0;

        for (let i = 0; i < folders?.length; i++) {
            length += folders[i].posters.length;
        }

        return length;
    };

    useEffect(() => {
        getAllFolders();
        getAllTags();
    }, []);

    useEffect(() => {
        if (!session && !sessionLoading) {
            navigate("/login");
        }
    }, [session, sessionLoading]);

    return (
        <>
            <Layout>
                <div className="flex items-center justify-between bg-white p-4">
                    <div className="flex items-center">
                        <p className="text-xl font-medium tracking-wider mr-3">
                            Posters
                        </p>
                        <div className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3 mr-3">
                            <FolderIcon className="!text-lg text-[#4B81D7] mr-1" />
                            <p className="text-sm text-[#4B81D7] ml-1">
                                {folders?.length}
                            </p>
                        </div>
                        <div className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3">
                            <DescriptionIcon className="!text-lg text-[#4B81D7] mr-1" />
                            <p className="text-sm text-[#4B81D7] ml-1">
                                {getPostersLength()}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="px-2 mr-3 border border-gray-400 rounded-lg">
                            <SearchIcon className="text-[#4b82d7]" />
                            <input
                                type="text"
                                placeholder="Search"
                                className="bg-transparent outline-none p-2 w-96"
                            />
                        </div>

                        <div className="px-2 mr-3 border border-gray-400 rounded-lg">
                            <FilterAltIcon className="text-[#4b82d7]" />

                            <select className="bg-transparent outline-none p-2 w-44">
                                <option value="">Select Tag</option>

                                {tags?.map((tag, index) => {
                                    return (
                                        <option key={index} value={tag.name}>
                                            {tag.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div
                            className="flex items-center bg-[#4b82d7] text-white py-2 px-4 rounded-lg cursor-pointer"
                            onClick={() => {
                                setAddFolderModal(true);
                            }}
                        >
                            <AddCircleIcon className="mr-2" />
                            <p>Add Folder</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap w-full bg-[#eeeeee] p-5">
                    {folders?.map((folder, index) => {
                        return (
                            <div
                                key={index}
                                className="relative flex flex-col justify-center bg-white p-4 w-52 h-44 border border-gray-400 rounded-lg mr-5 mb-5 cursor-pointer"
                                onClick={() => {
                                    navigate(`/posters/${folder._id}`);
                                }}
                            >
                                <p className="text-xl font-semibold tracking-wider text-center mb-10">
                                    {folder.name}
                                </p>

                                <div className="absolute left-0 bottom-3 w-full flex items-center justify-between px-3">
                                    <div className="flex items-center justify-between bg-[#e4ecf9] rounded-md p-2 px-3">
                                        <DescriptionIcon className="!text-lg text-[#4B81D7] mr-1" />
                                        <p className="text-sm text-[#4B81D7] ml-1">
                                            {folder.posters.length}
                                        </p>
                                    </div>

                                    <div className="bg-[#e7f9ef] rounded-md p-2 px-3">
                                        <p className="text-sm text-[#1db304]">
                                            {folder.tag}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* add folder modal */}

                <Transition.Root show={addFolderModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50"
                        onClose={() => {
                            setAddFolderModal(false);
                        }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-xl font-semibold tracking-wider mb-3">
                                                Add Folder
                                            </p>

                                            <CloseIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setAddFolderModal(false);
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <p className="mb-1 text-sm">
                                                Folder Name
                                            </p>

                                            <input
                                                name="name"
                                                type="text"
                                                placeholder="Poster Name"
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                value={folder?.name}
                                                onChange={(e) => {
                                                    setFolder({
                                                        ...folder,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col">
                                            <p className="mb-1 text-sm">Tag</p>

                                            <select
                                                className="p-2 border border-gray-400 outline-none rounded-lg"
                                                onChange={(e) => {
                                                    setFolder({
                                                        ...folder,
                                                        tag: e.target.value,
                                                    });
                                                }}
                                            >
                                                <option value="">
                                                    Select Tag
                                                </option>

                                                {tags?.map((tag, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={tag.name}
                                                        >
                                                            {tag.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <Button
                                            title="Save"
                                            className="mt-4 inline-flex w-full justify-center rounded-md bg-[#4b82d7] px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none"
                                            loading={isLoading}
                                            onClick={createFolder}
                                        />
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </Layout>

            <Popup
                type={popupDetail?.type}
                text={popupDetail?.text}
                isPopup={isPopup}
            />
        </>
    );
};

export default Posters;
