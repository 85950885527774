import React from "react";
import Lottie from "lottie-react";

import animationData from "../../Assets/Animations/animation.json";

const Spinner = () => {
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-[60]">
                <div className="w-20 h-20">
                    <Lottie animationData={animationData} />
                </div>
            </div>
        </>
    );
};

export default Spinner;
