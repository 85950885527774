import { Sidebar } from "../Sidebar";

export const Layout = ({ children }) => {
    return (
        <div className="flex flex-row w-screen overflow-hidden">
            <Sidebar />
            <div className="w-full overflow-y-auto bg-[#eeeeee]">
                {children}
            </div>
        </div>
    );
};
