import React, { useCallback } from "react";
import { GoogleMap } from "@react-google-maps/api";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const Map = ({ map, mapStyle, setMap, height = "600px" }) => {
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const containerStyle = {
    width: "100%",
    height: height,
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={(map) => setMap(map)}
      onUnmount={onUnmount}
      options={{
        styles: mapStyle,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        keyboardShortcuts: false,
      }}
    ></GoogleMap>
  );
};

export default React.memo(Map);
