import { useEffect } from "react";
import { getFlags, getBanners } from "../../Util/Common";

import "./map-style.css";

export const MapLayout = ({
  type,
  countryFontSize,
  capitalFont,
  coordinateFontSize,
  coordinateFont,
  showCoordinates,
  capital,
  showCapital,
  capitalFontSize,
  country,
  lng,
  lat,
  showFlag,
  showBanner,
  countryCode,
  countryFont,
}) => {
  if (type === "Abstract") {
    return (
      <div className="absolute top-0 left-0 right-0 py-4 px-2 w-full bg-white">
        <div className="flex justify-between items-center">
          <p
            className={`font-extrabold text-${countryFontSize} mt-1 capitalized font-${countryFont}`}
          >
            {!showFlag && !showBanner ? (
              <></>
            ) : (
              <>
                {getFlags(countryCode) !== "" && (
                  <div className="flex items-center justify-center mb-2">
                    {showFlag && (
                      <img
                        className="w-12 h-10 mr-3"
                        src={getFlags(countryCode)}
                        alt=""
                        srcset=""
                      />
                    )}

                    {showBanner && (
                      <img
                        className="h-10"
                        src={getBanners(countryCode)}
                        alt=""
                        srcset=""
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {country}
          </p>
          {showCapital && (
            <p
              className={`font-light text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
            >
              {capital}
            </p>
          )}
        </div>
        <div className="border-b border-b-black border-b-4 my-2" />
        {showCoordinates && (
          <p
            className={`font-light text-${coordinateFontSize} italic mt-1 font-${coordinateFont}`}
          >
            {lat}° N / {lng}° E
          </p>
        )}
      </div>
    );
  } else if (type === "Legend") {
    return (
      <div className="absolute bottom-10 m-auto left-0 right-0 py-4 w-[266px] bg-white">
        <p
          className={`text-center font-extrabold text-${countryFontSize} mt-1 capitalized font-${countryFont}`}
        >
          {!showFlag && !showBanner ? (
            <></>
          ) : (
            <>
              {getFlags(countryCode) !== "" && (
                <div className="flex items-center justify-center mb-2">
                  {showFlag && (
                    <img
                      className="w-12 h-10 mr-3"
                      src={getFlags(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}

                  {showBanner && (
                    <img
                      className="h-10"
                      src={getBanners(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}
                </div>
              )}
            </>
          )}
          {country}
        </p>
        {showCapital && (
          <p
            className={`text-center font-light text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
          >
            {capital}
          </p>
        )}
        {showCoordinates && (
          <p
            className={`text-center font-light text-${coordinateFontSize} italic mt-1 font-${coordinateFont}`}
          >
            {lat}° N / {lng}° E
          </p>
        )}
      </div>
    );
  } else if (type === "Modern") {
    return (
      <div className="absolute bottom-0 left-0 right-0 py-3 w-full bg-gradient-white-opacity">
        <p
          className={`text-center font-extrabold text-${countryFontSize} mt-1 capitalized font-${countryFont}`}
        >
          {!showFlag && !showBanner ? (
            <></>
          ) : (
            <>
              {getFlags(countryCode) !== "" && (
                <div className="flex items-center justify-center mb-2">
                  {showFlag && (
                    <img
                      className="w-12 h-10 mr-3"
                      src={getFlags(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}

                  {showBanner && (
                    <img
                      className="h-10"
                      src={getBanners(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}
                </div>
              )}
            </>
          )}
          {country}
        </p>
        {showCapital && (
          <p
            className={`text-center font-light text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
          >
            {capital}
          </p>
        )}
        {showCoordinates && (
          <p
            className={`text-center font-light text-${coordinateFontSize} italic mt-1 font-${coordinateFont}`}
          >
            {lat}° N / {lng}° E
          </p>
        )}
      </div>
    );
  } else if (type === "Smooth") {
    return (
      <div className="absolute bottom-0 left-0 right-0 py-4 px-2 w-full bg-white flex justify-between items-center">
        <p
          className={`font-extrabold text-${countryFontSize} mt-1 capitalized font-${countryFont}`}
        >
          {!showFlag && !showBanner ? (
            <></>
          ) : (
            <>
              {getFlags(countryCode) !== "" && (
                <div className="flex items-center justify-center mb-2">
                  {showFlag && (
                    <img
                      className="w-12 h-10 mr-3"
                      src={getFlags(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}

                  {showBanner && (
                    <img
                      className="h-10"
                      src={getBanners(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}
                </div>
              )}
            </>
          )}
          {country}
        </p>
        <div>
          {showCapital && (
            <p
              className={`font-light text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
            >
              {capital}
            </p>
          )}
          {showCoordinates && (
            <p
              className={`font-light text-${coordinateFontSize} italic mt-1 font-${coordinateFont}`}
            >
              {lat}° N / {lng}° E
            </p>
          )}
        </div>
      </div>
    );
  } else if (type === "Simple") {
    return (
      <div className="absolute bottom-0 left-0 right-0 py-4 px-2 w-full bg-white ">
        <p
          className={`font-extrabold text-center text-${countryFontSize} mt-1 capitalized font-${countryFont}`}
        >
          {!showFlag && !showBanner ? (
            <></>
          ) : (
            <>
              {getFlags(countryCode) !== "" && (
                <div className="flex items-center justify-center mb-2">
                  {showFlag && (
                    <img
                      className="w-12 h-10 mr-3"
                      src={getFlags(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}

                  {showBanner && (
                    <img
                      className="h-10"
                      src={getBanners(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}
                </div>
              )}
            </>
          )}
          {country}
        </p>

        {showCapital && (
          <p
            className={`font-light text-center  text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
          >
            {capital}
          </p>
        )}
        {showCoordinates && (
          <p
            className={`font-light text-center  text-${coordinateFontSize} italic mt-1 font-${coordinateFont}`}
          >
            {lat}° N / {lng}° E
          </p>
        )}
      </div>
    );
  } else if (type === "Bohemian") {
    return (
      <div className="absolute bottom-0 left-0 right-0  px-2 w-full bg-white ">
        <div className="border-t border-t-4 border-t-black" />
        <p
          className={`font-extrabold text-${countryFontSize} mt-1 capitalized font-${countryFont} pt-2`}
        >
          {!showFlag && !showBanner ? (
            <></>
          ) : (
            <>
              {getFlags(countryCode) !== "" && (
                <div className="flex items-center justify-center mb-2">
                  {showFlag && (
                    <img
                      className="w-12 h-10 mr-3"
                      src={getFlags(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}

                  {showBanner && (
                    <img
                      className="h-10"
                      src={getBanners(countryCode)}
                      alt=""
                      srcset=""
                    />
                  )}
                </div>
              )}
            </>
          )}
          {country}
        </p>

        {showCapital && (
          <p
            className={`font-light text-${capitalFontSize} mt-1 capitalized font-${capitalFont}`}
          >
            {capital}
          </p>
        )}
        {showCoordinates && (
          <p
            className={`font-light text-${coordinateFontSize} italic mt-1 font-${coordinateFont} pb-2`}
          >
            {lat}° N / {lng}° E
          </p>
        )}
      </div>
    );
  }
};
