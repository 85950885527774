import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import CloseIcon from "@mui/icons-material/Close";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import RiseLoader from "react-spinners/RiseLoader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Cookies from "js-cookie";
import axios from "axios";

import MemoExport from "../../Assets/Export";
import MemoSave from "../../Assets/Save";

import { Layout } from "../../Components/Layout";
import { Constant } from "../../Constant";
import mapStyle from "../../Util/MapStyles";

import { Button as CustomButton } from "../../Components";

import { toPng } from "html-to-image";

import {
  Card,
  DropDown,
  MapLayout,
  SelectableButton,
  TextField,
} from "../../Components";
import Map from "../../Components/Map";

import "./style.css";

import Popup from "../../Components/Popup";
import configSettings from "../../config";
import useSession from "../../Hooks/get-session";
import Spinner from "../../Components/Spinner";

export default function Dashboard() {
  const { data: session, isLoading: sessionLoading } = useSession();

  const elementRef = useRef(null);

  const [folder, setFolder] = useState();
  const [folders, setFolders] = useState([]);

  const [savePosterModal, setSavePosterModal] = useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [popupDetail, setPopupDetail] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const saveAsPng = () => {
    setTimeout(() => {
      toPng(elementRef.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "poster.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  };

  const saveAsPdf = () => {
    setTimeout(async () => {
      try {
        const dataUrl = await toPng(elementRef.current, {
          cacheBust: false,
        });

        // Convert data URL to a Blob
        const blob = dataURItoBlob(dataUrl);

        // Create a new Image element
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        // Wait for the Image element to load
        img.onload = () => {
          // Create a new jsPDF instance
          const pdf = new jsPDF({
            orientation: isLandscape ? "landscape" : "portrait",
            unit: "mm",
            format: "a4",
          });

          // Add the image to the PDF
          pdf.addImage(
            img,
            "PNG",
            0,
            0,
            pdf.internal.pageSize.getWidth(),
            pdf.internal.pageSize.getHeight()
          );

          // Save the PDF file
          pdf.save("poster.pdf");
        };
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }, 1000);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_APIKEY,
    libraries: ["places"],
  });

  const [activeTab, setActiveTab] = useState(0);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const inputRef = useRef();

  const [isLandscape, setIsLandscape] = useState(false);
  const [imageMode, setImageMode] = useState(0);
  const [layoutIndex, setLayoutIndex] = useState(0);
  const [layoutMode, setLayoutMode] = useState(Constant.LAYOUT[0].name);
  const [styleMode, setStyleMode] = useState(mapStyle.Modern);
  const [styleIndex, setStyleIndex] = useState(0);

  const [countryFont, setCountryFont] = useState("Ubuntu");
  const [capitalFont, setCapitalFont] = useState("Ubuntu");
  const [coordinateFont, setCoordinateFont] = useState("Ubuntu");

  const [countryFontSize, setCountryFontSize] = useState("2xl");
  const [capitalFontSize, setCapitalFontSize] = useState("xs");
  const [coordinateFontSize, setCoordinateFontSize] = useState("xs");

  const [showCapita, setShowCapital] = useState(true);
  const [showCoordinates, setShowCoordinates] = useState(true);
  const [showFlag, setShowFlag] = useState(true);
  const [showBanner, setShowBanner] = useState(true);

  const [address, setAddress] = useState({
    countryCode: "",
    country: "",
    capital: "",
    lat: 11.511138,
    lng: 45.042282,
  });

  useEffect(() => {
    const goTo = () => {
      try {
        if (map) {
          const center = {
            lat: address.lat,
            lng: address.lng,
          };
          map.panTo(center);
        }
      } catch (error) {
        console.log(error);
      }
    };
    goTo();
  }, [address.lat, address.lng]);

  const handlePlaceChange = () => {
    try {
      const [place] = inputRef.current.getPlaces();
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();

      setAddress({
        ...address,
        country: place.address_components[3]
          ? place.address_components[3]?.long_name
          : place.address_components[0]?.long_name,
        capital: place.address_components[3]
          ? place.address_components[0]?.long_name
          : "",
        countryCode: place.address_components[3]
          ? place.address_components[3]?.short_name
          : place.address_components[0]?.short_name,
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      setPopupDetail({
        type: "Error",
        text: "Selected invalid location",
      });

      setIsPopup(true);
      setTimeout(function () {
        setIsPopup(false);
      }, 4000);
    }
  };

  const onHandleMapStyle = (idx, style) => {
    setStyleIndex(idx);
    setStyleMode(style);
  };

  const handleLayout = (idx, style) => {
    setLayoutIndex(idx);
    setLayoutMode(style);
  };

  const onHandleInput = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLandscapeButtonClick = () => {
    setImageMode(1);
    setIsLandscape(true);
  };

  const handlePortraitButtonClick = () => {
    setImageMode(0);
    setIsLandscape(false);
  };

  const savePoster = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const dataUrl = await toPng(elementRef.current, {
          cacheBust: false,
        });

        // Convert data URL to a Blob
        const blob = dataURItoBlob(dataUrl);

        // Create FormData
        const formData = new FormData();
        formData.append("file", blob, "poster.png");

        // Make axios POST request
        const result = await axios.post(
          configSettings.publicServerUrl + "/uploadPoster",
          formData,
          {
            headers: {
              "access-token": Cookies.get("access-token"),
              "content-type": "multipart/form-data",
            },
          }
        );

        setSavePosterModal(false);

        setPopupDetail({
          type: "Success",
          text: "Poster has been successfully saved",
        });

        setIsPopup(true);

        setTimeout(function () {
          setIsPopup(false);
        }, 4000);

        setIsLoading(false);

        const response = result.data;

        // add poster in almub
        await axios.put(
          configSettings.publicServerUrl + `/addPosterInFolder/${folder}`,
          {
            posterPath: configSettings.serverUrl + "/" + response.filePath,
            posterType: layoutMode,
          },
          {
            headers: {
              "access-token": Cookies.get("access-token"),
            },
          }
        );

        setIsLoading(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setIsLoading(false);
      }
    }, 1000);
  };

  // Function to convert data URL to Blob
  function dataURItoBlob(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const getAllFolders = async () => {
    try {
      // setIsSpinner(true);
      const result = await axios.get(configSettings.serverUrl + "/getFolders", {
        headers: {
          "access-token": Cookies.get("access-token"),
        },
      });

      // setIsSpinner(false);

      const response = result.data;

      setFolders(response.data);
    } catch (error) {
      console.log(error);
      // setIsSpinner(false);
    }
  };

  useEffect(() => {
    getAllFolders();
  }, []);

  useEffect(() => {
    if (!session && !sessionLoading) {
      navigate("/login");
    }
  }, [session, sessionLoading]);

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <Layout>
        <div className="grid lg:grid-cols-3 sm:grid-rows-1 gap-2 h-screen">
          <div className="bg-white lg:col-span-1">
            <Tabs value={activeTab}>
              <TabsHeader
                className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-4 border-primary shadow-none rounded-none",
                }}
              >
                {Constant.TABS.map((item, idx) => (
                  <Tab
                    key={idx}
                    value={idx}
                    onClick={() => setActiveTab(idx)}
                    className={
                      activeTab === idx
                        ? "text-primary font-PtSans text-[16px]"
                        : "text-placeholder font-PtSans text-[16px]"
                    }
                  >
                    <div className="flex items-center gap-2">
                      <div>{item.icon}</div>
                      {item.name}
                    </div>
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                <TabPanel key={0} value={0}>
                  <form className="flex flex-col gap-5 mt-[40px]">
                    <div className="flex flex-col gap-1">
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceChange}
                      >
                        <TextField
                          name="search"
                          label="Search"
                          variant="primary"
                          placeholder="Search location"
                        />
                      </StandaloneSearchBox>
                    </div>
                    <div className="flex flex-row gap-4">
                      <TextField
                        name="country"
                        label="Country"
                        value={address.country}
                        variant="primary"
                        placeholder="Country"
                        onChange={onHandleInput}
                      />
                      <DropDown
                        label="Font"
                        value={countryFont}
                        data={Constant.FONTS_TYPE}
                        onChange={(e) => setCountryFont(e.target.value)}
                      />
                      <DropDown
                        label="Size"
                        value={countryFontSize}
                        data={Constant.FONT_SIZES}
                        onChange={(e) => setCountryFontSize(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-row gap-4">
                      <TextField
                        name="capital"
                        value={address.capital}
                        label="Capital"
                        variant="primary"
                        placeholder="Capital"
                        checkbox
                        checked={showCapita}
                        onChangeCheckbox={() => setShowCapital(!showCapita)}
                        onChange={onHandleInput}
                      />

                      <DropDown
                        label="Font"
                        value={capitalFont}
                        data={Constant.FONTS_TYPE}
                        onChange={(e) => setCapitalFont(e.target.value)}
                      />
                      <DropDown
                        label="Size"
                        value={capitalFontSize}
                        data={Constant.FONT_SIZES.slice(0, 5)}
                        onChange={(e) => setCapitalFontSize(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center">
                      {showFlag ? (
                        <input
                          type="checkbox"
                          checked
                          value={showFlag}
                          onChange={() => {
                            setShowFlag(!showFlag);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          value={showFlag}
                          onChange={() => {
                            setShowFlag(!showFlag);
                          }}
                        />
                      )}

                      <p className="ml-2 font-semibold text-[14px] text-black text-sm">
                        Flag
                      </p>
                    </div>

                    <div className="flex items-center">
                      {showBanner ? (
                        <input
                          type="checkbox"
                          checked
                          value={showFlag}
                          onChange={() => {
                            setShowBanner(!showBanner);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          value={showFlag}
                          onChange={() => {
                            setShowBanner(!showBanner);
                          }}
                        />
                      )}

                      <p className="ml-2 font-semibold text-[14px] text-black text-sm">
                        Banner
                      </p>
                    </div>

                    <div className="flex flex-row gap-4">
                      <TextField
                        name="latitude"
                        label="Coordinates"
                        variant="primary"
                        value={`${address.lat} / ${address.lng}`}
                        placeholder="Coordinates"
                        checkbox
                        checked={showCoordinates}
                        onChangeCheckbox={() =>
                          setShowCoordinates(!showCoordinates)
                        }
                      />
                      <DropDown
                        label="Font"
                        value={coordinateFont}
                        data={Constant.FONTS_TYPE}
                        onChange={(e) => setCoordinateFont(e.target.value)}
                      />
                      <DropDown
                        label="Size"
                        value={coordinateFontSize}
                        data={Constant.FONT_SIZES.slice(0, 5)}
                        onChange={(e) => setCoordinateFontSize(e.target.value)}
                      />
                    </div>
                  </form>
                </TabPanel>
                <TabPanel key={1} value={1}>
                  <div className="flex flex-col gap-5 mt-[40px]">
                    <div className="grid grid-cols-2 gap-5">
                      <SelectableButton
                        title="Portrait"
                        selected={imageMode === 0}
                        onClick={handlePortraitButtonClick}
                      />
                      <SelectableButton
                        title="Landscape"
                        selected={imageMode === 1}
                        onClick={handleLandscapeButtonClick}
                      />
                    </div>
                    <p className="text-sm font-semibold text-black font-PtSans text-[14px]">
                      Layout
                    </p>
                    <div className="grid grid-cols-3 gap-5">
                      {Constant.LAYOUT.map((item, idx) => (
                        <SelectableButton
                          key={idx}
                          title={item.name}
                          selected={layoutIndex === idx}
                          onClick={() => handleLayout(idx, item.name)}
                        />
                      ))}
                    </div>
                    <p className="text-sm font-semibold text-black font-PtSans text-[14px]">
                      Map Style
                    </p>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-5">
                      {Constant.MAP_STYLE.map((item, idx) => (
                        <Card
                          key={item.id}
                          title={item.name}
                          icon={item.icon}
                          selected={styleIndex === idx}
                          onClick={() => onHandleMapStyle(idx, item.mapStyle)}
                        />
                      ))}
                    </div>
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </div>
          <div className="lg:col-span-2 flex flex-col justify-center items-center bg-gray-200 ">
            <div
              className={`relative border border-4 border-black ${
                isLandscape
                  ? " min-h-[100px] lg:min-w-[700px]"
                  : "min-h-[600px] lg:min-w-[500px]"
              }  sm:min-w-[350px]`}
            >
              <div ref={elementRef} className="border border-8 border-white">
                <Map
                  mapStyle={styleMode}
                  map={map}
                  setMap={setMap}
                  height={isLandscape ? "500px" : "600px"}
                />

                {address.country && (
                  <MapLayout
                    type={layoutMode}
                    countryFont={countryFont}
                    countryFontSize={countryFontSize}
                    capitalFont={capitalFont}
                    coordinateFontSize={coordinateFontSize}
                    coordinateFont={coordinateFont}
                    showCoordinates={showCoordinates}
                    capital={address.capital}
                    showCapital={showCapita}
                    capitalFontSize={capitalFontSize}
                    country={address.country}
                    lng={address.lng}
                    lat={address.lat}
                    showFlag={showFlag}
                    showBanner={showBanner}
                    countryCode={address.countryCode}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4">
              <Button
                className="flex items-center gap-1 bg-secondary capitalize"
                size="sm"
                onClick={() => {
                  setSavePosterModal(true);
                }}
              >
                <div className="lg:inline-flex md:inline-flex sm:hidden">
                  <MemoSave />
                </div>
                Save
              </Button>
              <Button
                size="sm"
                className="flex items-center gap-1 capitalize bg-primary"
                onClick={() => {
                  saveAsPng();
                }}
              >
                <div className="lg:inline-flex md:inline-flex sm:hidden">
                  <MemoExport />
                </div>
                Export PNG
              </Button>
              <Button
                size="sm"
                className="flex items-center capitalize gap-1 bg-primary"
                onClick={() => {
                  saveAsPdf();
                }}
              >
                <div className="lg:inline-flex md:inline-flex sm:hidden">
                  <MemoExport />
                </div>
                Export PDF
              </Button>
            </div>
          </div>
        </div>

        {/* save poster modal */}

        <Transition.Root show={savePosterModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => {
              setSavePosterModal(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div className="flex items-center justify-between">
                      <p className="text-xl font-semibold tracking-wider mb-3">
                        Save Poster
                      </p>

                      <CloseIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setSavePosterModal(false);
                        }}
                      />
                    </div>

                    <div className="flex flex-col">
                      <p className="mb-1 text-sm">Folder</p>

                      <select
                        name="tag"
                        className="p-2 border border-gray-400 outline-none rounded-lg"
                        value={folder}
                        onChange={(e) => {
                          setFolder(e.target.value);
                        }}
                      >
                        <option value="">Select Folder</option>

                        {folders?.map((folder, index) => {
                          return (
                            <option key={index} value={folder._id}>
                              {folder.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <CustomButton
                      title="Save"
                      className="mt-4 inline-flex w-full justify-center rounded-md bg-[#4b82d7] px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none"
                      loading={isLoading}
                      onClick={savePoster}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Layout>

      <Popup
        type={popupDetail?.type}
        text={popupDetail?.text}
        isPopup={isPopup}
      />
    </>
  );
}
