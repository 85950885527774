import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Cookies from "js-cookie";

import { Constant } from "../../Constant";
import MemoLogoMin from "../../Assets/LogoMin";
import Popup from "../Popup";

const linkClass =
    "flex items-center gap-2 font-[500] px-3 py-2 hover:bg-sidebarlink text-white hover:no-underline rounded-lg text-[15px] font-PTSans";

export const Sidebar = () => {
    const [isPopup, setIsPopup] = useState(false);
    const [popupDetail, setPopupDetail] = useState();

    const navigate = useNavigate();

    const logout = async () => {
        Cookies.remove("access-token");

        setPopupDetail({
            type: "Success",
            text: "Your account has been successfully logged out",
        });

        setIsPopup(true);

        setTimeout(function () {
            setIsPopup(false);
            navigate("/login");
        }, 2000);
    };
    return (
        <>
            <div className="bg-secondary w-48 h-screen p-3 flex flex-col shadow lg:inline-flex md:inline-flex sm:hidden">
                <div className="flex items-center justify-center">
                    <MemoLogoMin />
                </div>
                <div className="py-8 flex flex-1 flex-col gap-1">
                    {Constant.SIDEBAR_TOP_LINKS.map((link, idx) => (
                        <SidebarLink key={idx} link={link} />
                    ))}
                </div>
                <div className="flex flex-col gap-1 pt-2 border-t border-sidebarlink">
                    <a
                        className={classNames(
                            linkClass,
                            "cursor-pointer font-[500] text-[15px] font-PTSans text-white"
                        )}
                        onClick={logout}
                    >
                        <span className="text-xl">
                            <ArrowRightIcon className="h-5 w-5 text-primary" />
                        </span>
                        Sign out
                    </a>
                </div>
            </div>

            <Popup
                type={popupDetail?.type}
                text={popupDetail?.text}
                isPopup={isPopup}
            />
        </>
    );
};

function SidebarLink({ link }) {
    const { pathname } = useLocation();

    return (
        <Link
            to={link.to}
            className={classNames(
                pathname === link.to && "bg-sidebarlink",
                linkClass
            )}
        >
            <span className="text-xl">{link.icon}</span>
            {link.name}

            {/* <ChevronRightIcon className="h-3 w-3" /> */}
        </Link>
    );
}
