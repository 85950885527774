import {
    FolderIcon,
    MapIcon,
    MapPinIcon,
    PencilSquareIcon,
    TagIcon,
} from "@heroicons/react/24/solid";

import mapStyle from "../Util/MapStyles";

import ModernImg from "../Assets/Modern.png";
import OceanImg from "../Assets/Ocean.png";
import AsphaltImg from "../Assets/Asphalt.png";
import AbstractImg from "../Assets/Abstract.png";
import GalaImg from "../Assets/Gala.png";
import SkyImg from "../Assets/Sky.png";
import MapStyles from "../Util/MapStyles";

export const Constant = {
    MAP_GL_TOKEN:
        "pk.eyJ1Ijoia2FobmRpc20yMDJmLTAxMSIsImEiOiJja3VwZGFydXY0bDh5Mm9vODZjeHp2OXNtIn0.8m1giZgU7p90Nq_OJ4S7DA",
    TOKEN: "TOKEN",
    GOOGLE_API_KEY: "AIzaSyAXka145qM6khsqbvG6-N8MtwGujwi4baI",
    SIDEBAR_TOP_LINKS: [
        {
            name: "Create",
            to: "/",
            icon: <MapIcon className="h-5 w-5 text-primary" />,
        },
        {
            name: "Posters",
            to: "/posters",
            icon: <FolderIcon className="h-5 w-5 text-primary" />,
        },
        {
            name: "Tags",
            to: "/tags",
            icon: <TagIcon className="h-5 w-5 text-primary" />,
        },
    ],
    TABS: [
        {
            name: "Map",
            icon: <MapPinIcon className="h-5 w-5" />,
        },
        {
            name: "Style",
            icon: <PencilSquareIcon className="h-5 w-5" />,
        },
    ],
    LAYOUT: [
        {
            name: "Legend",
        },
        {
            name: "Abstract",
        },
        {
            name: "Modern",
        },
        {
            name: "Smooth",
        },
        {
            name: "Simple",
        },
        {
            name: "Bohemian",
        },
    ],
    MAP_STYLE: [
        {
            id: 1,
            name: "Modern",
            icon: ModernImg,
            mapStyle: mapStyle.Modern,
        },
        {
            id: 2,
            name: "Ocean",
            icon: OceanImg,
            mapStyle: mapStyle.Ocean,
        },
        {
            id: 3,
            name: "Asphalt",
            icon: AsphaltImg,
            mapStyle: mapStyle.Asphalt,
        },
        {
            id: 4,
            name: "Abstract",
            icon: AbstractImg,
            mapStyle: mapStyle.Abstract,
        },
        {
            id: 5,
            name: "Gala",
            icon: GalaImg,
            mapStyle: MapStyles.Gala,
        },
        {
            id: 6,
            name: "Sky",
            icon: SkyImg,
            mapStyle: mapStyle.Sky,
        },
    ],
    FLAGS: [
        {
            name: "Pakistan",
            src: "../Assets/Flags/Asia/Pakistan.png",
        },
    ],
    FONT_SIZES: [
        {
            name: "12",
            value: "xs",
        },
        {
            name: "14",
            value: "sm",
        },
        {
            name: "16",
            value: "base",
        },
        {
            name: "18",
            value: "lg",
        },
        {
            name: "20",
            value: "xl",
        },
        {
            name: "24",
            value: "2xl",
        },
        {
            name: "30",
            value: "3xl",
        },
        {
            name: "36",
            value: "4xl",
        },
        {
            name: "48",
            value: "5xl",
        },
    ],
    FONTS_TYPE: [
        {
            name: "Ubuntu",
            value: "Ubuntu",
        },
        {
            name: "PTSans",
            value: "PTSans",
        },
        {
            name: "Roboto Condensed",
            value: "RobotoCondensed",
        },
        {
            name: "Oswald",
            value: "Oswald",
        },
        {
            name: "Merriweather",
            value: "Merriweather",
        },
    ],
};
