import * as React from "react";

function Save(props) {
  return (
    <svg width={25} height={24} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_240_765)">
        <path
          d="M5.821 3.25c-1.418 0-2.571 1.121-2.571 2.5v12.5c0 1.379 1.153 2.5 2.571 2.5H18.68c1.418 0 2.571-1.121 2.571-2.5V8.77c0-.665-.27-1.301-.751-1.77l-3.106-3.02a2.61 2.61 0 00-1.82-.73H5.82zm0 3.75c0-.691.575-1.25 1.286-1.25h7.714c.712 0 1.286.559 1.286 1.25v2.5c0 .691-.574 1.25-1.286 1.25H7.107c-.711 0-1.286-.559-1.286-1.25V7zm6.429 11.25c-1.418 0-2.571-1.121-2.571-2.5s1.153-2.5 2.571-2.5c1.418 0 2.571 1.121 2.571 2.5s-1.153 2.5-2.571 2.5z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_240_765">
          <path fill="#fff" transform="translate(3.25 2)" d="M0 0h18v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSave = React.memo(Save);
export default MemoSave;
