import React from "react";

export const Card = ({ title, icon, selected, onClick }) => {
  return (
    <div
      className={`flex flex-col items-center border cursor-pointer ${
        selected
          ? "text-black border-grayButton"
          : "border-grayCard text-placeholder"
      } rounded p-2`}
      onClick={onClick}
    >
      <p className="font-PTSans font-[600] ">{title}</p>
      <img src={icon} alt="title" className="object-fit" />
    </div>
  );
};
