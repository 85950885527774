import * as React from "react";

function Export(props) {
  return (
    <svg width={25} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.581 7.244v-4.34l4.341 4.34h-4.34zm-6.82-4.34h5.58v4.34c0 .992.827 1.24 1.24 1.24h4.34v4.96H9.145a.653.653 0 00-.647.638c0 .325.28.603.647.603h6.778v3.72c0 1.489-.827 1.86-1.24 1.86H4.76c-1.488 0-1.86-1.24-1.86-1.86V5.384c0-1.985 1.24-2.48 1.86-2.48zm11.16 11.78h3.557l-2.193 2.043a.59.59 0 000 .877.7.7 0 00.941 0l3.329-3.1a.59.59 0 000-.876l-3.33-3.102a.7.7 0 00-.94 0 .59.59 0 000 .876l2.193 2.043h-3.556v1.24z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoExport = React.memo(Export);
export default MemoExport;
