import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Dashboard from "./Views/Dashboard";
import LoginView from "./Views/Auth/LoginView";
import RegistrationView from "./Views/Auth/RegistrationView";
import UnknownView from "./Views";
import Posters from "./Views/posters";
import Tags from "./Views/tags";
import Folder from "./Views/posters/[folderId]";

import { ProtectedRoutes } from "./routes";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoutes>
                <Dashboard />
            </ProtectedRoutes>
        ),
    },
    {
        path: "/posters",
        element: <Posters />,
    },
    {
        path: "/posters/*",
        element: <Folder />,
    },
    {
        path: "/tags",
        element: <Tags />,
    },
    {
        path: "/login",
        element: <LoginView />,
    },
    {
        path: "/register",
        element: <RegistrationView />,
    },
    {
        path: "/*",
        element: <UnknownView />,
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
