import { Spinner } from "@material-tailwind/react";
import React from "react";

export const Button = ({ loading, title, icon, ...rest }) => {
  return (
    <button
      className="flex justify-center items-center p-2 rounded-lg text-white bg-primary font-[500] text-[14px] font-PtSans "
      {...rest}
    >
      {loading ? <Spinner className="h-6 w-6" color="white" /> : title}
    </button>
  );
};

export const SelectableButton = ({
  loading,
  title,
  icon,
  selected,
  ...rest
}) => {
  return (
    <button
      className={`flex w-full justify-center font-[600] items-center p-1 border ${
        selected
          ? "text-black border-grayButton"
          : "text-placeholder border-placeholder"
      } rounded  font-[500] text-[14px] font-PtSans  `}
      {...rest}
    >
      {loading ? <Spinner className="h-6 w-6" color="white" /> : title}
    </button>
  );
};
