import React, { useEffect, useState } from "react";
import { Button, TextField } from "../../../Components";

import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

import Popup from "../../../Components/Popup";
import configSettings from "../../../config";
import Memologo from "../../../Assets/logo";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/solid";
import useSession from "../../../Hooks/get-session";

export default function LoginView() {
  const { data: session } = useSession();

  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [popupDetail, setPopupDetail] = useState();

  useEffect(() => {
    if (session) {
      navigate("/");
    }
  }, [session]);

  const loginUser = async () => {
    if (!credentials?.email || !credentials.password) {
      setPopupDetail({
        type: "Warning",
        text: "Please enter email and password first",
      });

      setIsPopup(true);

      setTimeout(function () {
        setIsPopup(false);
      }, 4000);

      return;
    }

    try {
      setIsLoading(true);
      const result = await axios.post(configSettings.serverUrl + "/login", {
        email: credentials?.email,
        password: credentials?.password,
      });

      const response = result.data;

      Cookies.set("access-token", response.data, { expires: 7 });

      setIsLoading(false);

      setCredentials({ email: "", password: "" });

      setPopupDetail({
        type: "Success",
        text: "Your account has been successfully logged in",
      });

      setIsPopup(true);

      setTimeout(function () {
        setIsPopup(false);
        navigate("/");
      }, 4000);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 400) {
        setPopupDetail({
          type: "Warning",
          text: error.response.data.error,
        });

        setIsPopup(true);

        setTimeout(function () {
          setIsPopup(false);
        }, 4000);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen items-center justify-center bg-[#233654]">
        <Memologo />
        <div className="flex flex-col gap-3 mt-[35px] bg-white lg:p-[30px] sm:p-5 lg:w-[400px] md:w-[400px] sm:w-[330px] rounded-lg">
          <div className="flex flex-col gap-5 mt-4">
            <TextField
              label="Email"
              name="email"
              placeholder="Enter email"
              type="email"
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              leftIcon={<EnvelopeIcon className="h-5 w-5 text-primary" />}
            />
            <TextField
              label="Password"
              name="password"
              placeholder="Enter password"
              type="password"
              minLength={6}
              maxLength={12}
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              secured
              leftIcon={<KeyIcon className="h-5 w-5 text-primary" />}
            />

            <Button title="Login" loading={isLoading} onClick={loginUser} />
            <p className="text-sm text-center font-light">
              Don't you have a account?{" "}
              <span className="text-primary">
                <Link to="/register">Register</Link>
              </span>
            </p>
          </div>
        </div>
        <div className="mt-4 flex lg:w-[350px] sm:w-[330px] md:w-[400px] items-center justify-between">
          <p className="font-[500] text-[12px] text-white">© MapPro 2023</p>
          <div className="flex flex-row items-center gap-2 font-[500] text-[12px] text-white">
            <span>
              <EnvelopeIcon className="h-3 w-3" />
            </span>
            <p>andy@map-pro.com</p>
          </div>
        </div>
      </div>

      <Popup
        type={popupDetail?.type}
        text={popupDetail?.text}
        isPopup={isPopup}
      />
    </>
  );
}
