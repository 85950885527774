const getBanners = (countryCode) => {
    const code = countryCode?.toUpperCase();

    if (code === "AD") return "/banners/Andorra.png";
    if (code === "AE") return "/banners/United_Arab_Emirates.png";
    if (code === "AF") return "/banners/Afghanistan.png";
    if (code === "AG") return "/banners/Antigua_and_Barbuda.png";
    if (code === "AI") return "/banners/Anguilla.png";
    if (code === "AL") return "/banners/Albania.png";
    if (code === "AM") return "/banners/Armenia.png";
    if (code === "AO") return "/banners/Angola.png";
    if (code === "AQ") return "/banners/Antarctica.png";
    if (code === "AR") return "/banners/Argentina.png";
    if (code === "AS") return "/banners/American_Samoa.png";
    if (code === "AT") return "/banners/Austria.png";
    if (code === "AU") return "/banners/Australia.png";
    if (code === "AW") return "/banners/Aruba.png";
    if (code === "AX") return "/banners/Aland_Islands.png";
    if (code === "AZ") return "/banners/Azerbaijan.png";
    if (code === "BA") return "/banners/Bosnia_and_Herzegovina.png";
    if (code === "BB") return "/banners/Barbados.png";
    if (code === "BD") return "/banners/Bangladesh.png";
    if (code === "BE") return "/banners/Belgium.png";
    if (code === "BF") return "/banners/Burkina_Faso.png";
    if (code === "BG") return "/banners/Bulgaria.png";
    if (code === "BH") return "/banners/Bahrain.png";
    if (code === "BI") return "/banners/Burundi.png";
    if (code === "BJ") return "/banners/Benin.png";
    if (code === "BL") return "/banners/Saint_Barthélemy.png";
    if (code === "BM") return "/banners/Bermuda.png";
    if (code === "BN") return "/banners/Brunei.png";
    if (code === "BO") return "/banners/Bolivia.png";
    if (code === "BQ") return "/banners/Bonaire.png";
    if (code === "BR") return "/banners/Brazil.png";
    if (code === "BS") return "/banners/Bahamas.png";
    if (code === "BT") return "/banners/Bhutan.png";
    if (code === "BV") return "/banners/Bouvet_Island.png";
    if (code === "BW") return "/banners/Botswana.png";
    if (code === "BY") return "/banners/Belarus.png";
    if (code === "BZ") return "/banners/Belize.png";
    if (code === "CA") return "/banners/Canada.png";
    if (code === "CC") return "/banners/Cocos_Islands.png";
    if (code === "CD") return "/banners/Democratic_Republic_of_the_Congo.png";
    if (code === "CF") return "/banners/Central_African_Republic.png";
    if (code === "CG") return "/banners/Congo.png";
    if (code === "CH") return "/banners/Switzerland.png";
    if (code === "CI") return "/banners/Ivory_Coast.png";
    if (code === "CK") return "/banners/Cook_Islands.png";
    if (code === "CL") return "/banners/Chile.png";
    if (code === "CM") return "/banners/Cameroon.png";
    if (code === "CN") return "/banners/China.png";
    if (code === "CO") return "/banners/Colombia.png";
    if (code === "CR") return "/banners/Costa_Rica.png";
    if (code === "CU") return "/banners/Cuba.png";
    if (code === "CV") return "/banners/Cape_Verde.png";
    if (code === "CW") return "/banners/Curacao.png";
    if (code === "CX") return "/banners/Christmas_Island.png";
    if (code === "CY") return "/banners/Cyprus.png";
    if (code === "CZ") return "/banners/Czech_Republic.png";
    if (code === "DE") return "/banners/Germany.png";
    if (code === "DJ") return "/banners/Djibouti.png";
    if (code === "DK") return "/banners/Denmark.png";
    if (code === "DM") return "/banners/Dominica.png";
    if (code === "DO") return "/banners/Dominican_Republic.png";
    if (code === "DZ") return "/banners/Algeria.png";
    if (code === "EC") return "/banners/Ecuador.png";
    if (code === "EE") return "/banners/Estonia.png";
    if (code === "EG") return "/banners/Egypt.png";
    if (code === "EH") return "/banners/Western_Sahara.png";
    if (code === "ER") return "/banners/Eritrea.png";
    if (code === "ES") return "/banners/Spain.png";
    if (code === "ET") return "/banners/Ethiopia.png";
    if (code === "FI") return "/banners/Finland.png";
    if (code === "FJ") return "/banners/Fiji.png";
    if (code === "FK") return "/banners/Falkland_Islands.png";
    if (code === "FM") return "/banners/Micronesia.png";
    if (code === "FO") return "/banners/Faroe_Islands.png";
    if (code === "FR") return "/banners/France.png";
    if (code === "GA") return "/banners/Gabon.png";
    if (code === "GB") return "/banners/United_Kingdom.png";
    if (code === "GD") return "/banners/Grenada.png";
    if (code === "GE") return "/banners/Georgia.png";
    if (code === "GF") return "/banners/French_Guiana.png";
    if (code === "GG") return "/banners/Guernsey.png";
    if (code === "GH") return "/banners/Ghana.png";
    if (code === "GI") return "/banners/Gibraltar.png";
    if (code === "GL") return "/banners/Greenland.png";
    if (code === "GM") return "/banners/Gambia.png";
    if (code === "GN") return "/banners/Guinea.png";
    if (code === "GP") return "/banners/Guadeloupe.png";
    if (code === "GQ") return "/banners/Equatorial_Guinea.png";
    if (code === "GR") return "/banners/Greece.png";
    if (code === "GS")
        return "/banners/South_Georgia_and_the_South_Sandwich_Islands.png";
    if (code === "GT") return "/banners/Guatemala.png";
    if (code === "GU") return "/banners/Guam.png";
    if (code === "GW") return "/banners/Guinea-Bissau.png";
    if (code === "GY") return "/banners/Guyana.png";
    if (code === "HK") return "/banners/Hong_Kong.png";
    if (code === "HM") return "/banners/Heard_Island_and_McDonald_Islands.png";
    if (code === "HN") return "/banners/Honduras.png";
    if (code === "HR") return "/banners/Croatia.png";
    if (code === "HT") return "/banners/Haiti.png";
    if (code === "HU") return "/banners/Hungary.png";
    if (code === "ID") return "/banners/Indonesia.png";
    if (code === "IE") return "/banners/Ireland.png";
    if (code === "IL") return "/banners/Israel.png";
    if (code === "IM") return "/banners/Isle_of_Man.png";
    if (code === "IN") return "/banners/India.png";
    if (code === "IO") return "/banners/British_Indian_Ocean_Territory.png";
    if (code === "IQ") return "/banners/Iraq.png";
    if (code === "IR") return "/banners/Iran.png";
    if (code === "IS") return "/banners/Iceland.png";
    if (code === "IT") return "/banners/Italy.png";
    if (code === "JE") return "/banners/Jersey.png";
    if (code === "JM") return "/banners/Jamaica.png";
    if (code === "JO") return "/banners/Jordan.png";
    if (code === "JP") return "/banners/Japan.png";
    if (code === "KE") return "/banners/Kenya.png";
    if (code === "KG") return "/banners/Kyrgyzstan.png";
    if (code === "KH") return "/banners/Cambodia.png";
    if (code === "KI") return "/banners/Kiribati.png";
    if (code === "KM") return "/banners/Comoros.png";
    if (code === "KN") return "/banners/Saint_Kitts_and_Nevis.png";
    if (code === "KP") return "/banners/North_Korea.png";
    if (code === "KR") return "/banners/South_Korea.png";
    if (code === "KW") return "/banners/Kuwait.png";
    if (code === "KY") return "/banners/Cayman_Islands.png";
    if (code === "KZ") return "/banners/Kazakhstan.png";
    if (code === "LA") return "/banners/Laos.png";
    if (code === "LB") return "/banners/Lebanon.png";
    if (code === "LC") return "/banners/Saint_Lucia.png";
    if (code === "LI") return "/banners/Liechtenstein.png";
    if (code === "LK") return "/banners/Sri_Lanka.png";
    if (code === "LR") return "/banners/Liberia.png";
    if (code === "LS") return "/banners/Lesotho.png";
    if (code === "LT") return "/banners/Lithuania.png";
    if (code === "LU") return "/banners/Luxembourg.png";
    if (code === "LV") return "/banners/Latvia.png";
    if (code === "LY") return "/banners/Libya.png";
    if (code === "MA") return "/banners/Morocco.png";
    if (code === "MC") return "/banners/Monaco.png";
    if (code === "MD") return "/banners/Moldova.png";
    if (code === "ME") return "/banners/Montenegro.png";
    if (code === "MF") return "/banners/Saint_Martin.png";
    if (code === "MG") return "/banners/Madagascar.png";
    if (code === "MH") return "/banners/Marshall_Islands.png";
    if (code === "MK") return "/banners/North_Macedonia.png";
    if (code === "ML") return "/banners/Mali.png";
    if (code === "MM") return "/banners/Myanmar.png";
    if (code === "MN") return "/banners/Mongolia.png";
    if (code === "MO") return "/banners/Macao.png";
    if (code === "MP") return "/banners/Northern_Mariana_Islands.png";
    if (code === "MQ") return "/banners/Martinique.png";
    if (code === "MR") return "/banners/Mauritania.png";
    if (code === "MS") return "/banners/Montserrat.png";
    if (code === "MT") return "/banners/Malta.png";
    if (code === "MU") return "/banners/Mauritius.png";
    if (code === "MV") return "/banners/Maldives.png";
    if (code === "MW") return "/banners/Malawi.png";
    if (code === "MX") return "/banners/Mexico.png";
    if (code === "MY") return "/banners/Malaysia.png";
    if (code === "MZ") return "/banners/Mozambique.png";
    if (code === "NA") return "/banners/Namibia.png";
    if (code === "NC") return "/banners/New_Caledonia.png";
    if (code === "NE") return "/banners/Niger.png";
    if (code === "NF") return "/banners/Norfolk_Island.png";
    if (code === "NG") return "/banners/Nigeria.png";
    if (code === "NI") return "/banners/Nicaragua.png";
    if (code === "NL") return "/banners/Netherlands.png";
    if (code === "NO") return "/banners/Norway.png";
    if (code === "NP") return "/banners/Nepal.png";
    if (code === "NR") return "/banners/Nauru.png";
    if (code === "NU") return "/banners/Niue.png";
    if (code === "NZ") return "/banners/New_Zealand.png";
    if (code === "OM") return "/banners/Oman.png";
    if (code === "PA") return "/banners/Panama.png";
    if (code === "PE") return "/banners/Peru.png";
    if (code === "PF") return "/banners/French_Polynesia.png";
    if (code === "PG") return "/banners/Papua_New_Guinea.png";
    if (code === "PH") return "/banners/Philippines.png";
    if (code === "PK") return "/banners/Pakistan.png";
    if (code === "PL") return "/banners/Poland.png";
    if (code === "PM") return "/banners/Saint_Pierre_and_Miquelon.png";
    if (code === "PN") return "/banners/Pitcairn_Islands.png";
    if (code === "PR") return "/banners/Puerto_Rico.png";
    if (code === "PS") return "/banners/Palestine.png";
    if (code === "PT") return "/banners/Portugal.png";
    if (code === "PW") return "/banners/Palau.png";
    if (code === "PY") return "/banners/Paraguay.png";
    if (code === "QA") return "/banners/Qatar.png";
    if (code === "RE") return "/banners/Réunion.png";
    if (code === "RO") return "/banners/Romania.png";
    if (code === "RS") return "/banners/Serbia.png";
    if (code === "RU") return "/banners/Russia.png";
    if (code === "RW") return "/banners/Rwanda.png";
    if (code === "SA") return "/banners/Saudi_Arabia.png";
    if (code === "SB") return "/banners/Solomon_Islands.png";
    if (code === "SC") return "/banners/Seychelles.png";
    if (code === "SD") return "/banners/Sudan.png";
    if (code === "SE") return "/banners/Sweden.png";
    if (code === "SG") return "/banners/Singapore.png";
    if (code === "SH") return "/banners/Saint_Helena.png";
    if (code === "SI") return "/banners/Slovenia.png";
    if (code === "SJ") return "/banners/Svalbard_and_Jan_Mayen.png";
    if (code === "SK") return "/banners/Slovakia.png";
    if (code === "SL") return "/banners/Sierra_Leone.png";
    if (code === "SM") return "/banners/San_Marino.png";
    if (code === "SN") return "/banners/Senegal.png";
    if (code === "SO") return "/banners/Somalia.png";
    if (code === "SR") return "/banners/Suriname.png";
    if (code === "SS") return "/banners/South_Sudan.png";
    if (code === "ST") return "/banners/São_Tomé_and_Príncipe.png";
    if (code === "SV") return "/banners/El_Salvador.png";
    if (code === "SX") return "/banners/Sint_Maarten.png";
    if (code === "SY") return "/banners/Syria.png";
    if (code === "SZ") return "/banners/Swaziland.png";
    if (code === "TC") return "/banners/Turks_and_Caicos_Islands.png";
    if (code === "TD") return "/banners/Chad.png";
    if (code === "TF")
        return "/banners/French_Southern_and_Antarctic_Lands.png";
    if (code === "TG") return "/banners/Togo.png";
    if (code === "TH") return "/banners/Thailand.png";
    if (code === "TJ") return "/banners/Tajikistan.png";
    if (code === "TK") return "/banners/Tokelau.png";
    if (code === "TL") return "/banners/East_Timor.png";
    if (code === "TM") return "/banners/Turkmenistan.png";
    if (code === "TN") return "/banners/Tunisia.png";
    if (code === "TO") return "/banners/Tonga.png";
    if (code === "TR") return "/banners/Turkey.png";
    if (code === "TT") return "/banners/Trinidad_and_Tobago.png";
    if (code === "TV") return "/banners/Tuvalu.png";
    if (code === "TW") return "/banners/Taiwan.png";
    if (code === "TZ") return "/banners/Tanzania.png";
    if (code === "UA") return "/banners/Ukraine.png";
    if (code === "UG") return "/banners/Uganda.png";
    if (code === "UM")
        return "/banners/United_States_Minor_Outlying_Islands.png";
    if (code === "US") return "/banners/United_States.png";
    if (code === "UY") return "/banners/Uruguay.png";
    if (code === "UZ") return "/banners/Uzbekistan.png";
    if (code === "VA") return "/banners/Vatican_City.png";
    if (code === "VC") return "/banners/Saint_Vincent_and_the_Grenadines.png";
    if (code === "VE") return "/banners/Venezuela.png";
    if (code === "VG") return "/banners/British_Virgin_Islands.png";
    if (code === "VI") return "/banners/U.S._Virgin_Islands.png";
    if (code === "VN") return "/banners/Vietnam.png";
    if (code === "VU") return "/banners/Vanuatu.png";
    if (code === "WF") return "/banners/Wallis_and_Futuna.png";
    if (code === "WS") return "/banners/Samoa.png";
    if (code === "XK") return "/banners/Kosovo.png";
    if (code === "YE") return "/banners/Yemen.png";
    if (code === "YT") return "/banners/Mayotte.png";
    if (code === "ZA") return "/banners/South_Africa.png";
    if (code === "ZM") return "/banners/Zambia.png";
    return "";
};

const getFlags = (countryCode) => {
    const code = countryCode?.toUpperCase();

    if (code === "AD") return "/flags/Andorra.png";
    if (code === "AE") return "/flags/United_Arab_Emirates.png";
    if (code === "AF") return "/flags/Afghanistan.png";
    if (code === "AG") return "/flags/Antigua_and_Barbuda.png";
    if (code === "AI") return "/flags/Anguilla.png";
    if (code === "AL") return "/flags/Albania.png";
    if (code === "AM") return "/flags/Armenia.png";
    if (code === "AO") return "/flags/Angola.png";
    if (code === "AQ") return "/flags/Antarctica.png";
    if (code === "AR") return "/flags/Argentina.png";
    if (code === "AS") return "/flags/American_Samoa.png";
    if (code === "AT") return "/flags/Austria.png";
    if (code === "AU") return "/flags/Australia.png";
    if (code === "AW") return "/flags/Aruba.png";
    if (code === "AX") return "/flags/Aland_Islands.png";
    if (code === "AZ") return "/flags/Azerbaijan.png";
    if (code === "BA") return "/flags/Bosnia_and_Herzegovina.png";
    if (code === "BB") return "/flags/Barbados.png";
    if (code === "BD") return "/flags/Bangladesh.png";
    if (code === "BE") return "/flags/Belgium.png";
    if (code === "BF") return "/flags/Burkina_Faso.png";
    if (code === "BG") return "/flags/Bulgaria.png";
    if (code === "BH") return "/flags/Bahrain.png";
    if (code === "BI") return "/flags/Burundi.png";
    if (code === "BJ") return "/flags/Benin.png";
    if (code === "BL") return "/flags/Saint_Barthélemy.png";
    if (code === "BM") return "/flags/Bermuda.png";
    if (code === "BN") return "/flags/Brunei.png";
    if (code === "BO") return "/flags/Bolivia.png";
    if (code === "BQ") return "/flags/Bonaire.png";
    if (code === "BR") return "/flags/Brazil.png";
    if (code === "BS") return "/flags/Bahamas.png";
    if (code === "BT") return "/flags/Bhutan.png";
    if (code === "BV") return "/flags/Bouvet_Island.png";
    if (code === "BW") return "/flags/Botswana.png";
    if (code === "BY") return "/flags/Belarus.png";
    if (code === "BZ") return "/flags/Belize.png";
    if (code === "CA") return "/flags/Canada.png";
    if (code === "CC") return "/flags/Cocos_Islands.png";
    if (code === "CD") return "/flags/Democratic_Republic_of_the_Congo.png";
    if (code === "CF") return "/flags/Central_African_Republic.png";
    if (code === "CG") return "/flags/Congo.png";
    if (code === "CH") return "/flags/Switzerland.png";
    if (code === "CI") return "/flags/Ivory_Coast.png";
    if (code === "CK") return "/flags/Cook_Islands.png";
    if (code === "CL") return "/flags/Chile.png";
    if (code === "CM") return "/flags/Cameroon.png";
    if (code === "CN") return "/flags/China.png";
    if (code === "CO") return "/flags/Colombia.png";
    if (code === "CR") return "/flags/Costa_Rica.png";
    if (code === "CU") return "/flags/Cuba.png";
    if (code === "CV") return "/flags/Cape_Verde.png";
    if (code === "CW") return "/flags/Curacao.png";
    if (code === "CX") return "/flags/Christmas_Island.png";
    if (code === "CY") return "/flags/Cyprus.png";
    if (code === "CZ") return "/flags/Czech_Republic.png";
    if (code === "DE") return "/flags/Germany.png";
    if (code === "DJ") return "/flags/Djibouti.png";
    if (code === "DK") return "/flags/Denmark.png";
    if (code === "DM") return "/flags/Dominica.png";
    if (code === "DO") return "/flags/Dominican_Republic.png";
    if (code === "DZ") return "/flags/Algeria.png";
    if (code === "EC") return "/flags/Ecuador.png";
    if (code === "EE") return "/flags/Estonia.png";
    if (code === "EG") return "/flags/Egypt.png";
    if (code === "EH") return "/flags/Western_Sahara.png";
    if (code === "ER") return "/flags/Eritrea.png";
    if (code === "ES") return "/flags/Spain.png";
    if (code === "ET") return "/flags/Ethiopia.png";
    if (code === "FI") return "/flags/Finland.png";
    if (code === "FJ") return "/flags/Fiji.png";
    if (code === "FK") return "/flags/Falkland_Islands.png";
    if (code === "FM") return "/flags/Micronesia.png";
    if (code === "FO") return "/flags/Faroe_Islands.png";
    if (code === "FR") return "/flags/France.png";
    if (code === "GA") return "/flags/Gabon.png";
    if (code === "GB") return "/flags/United_Kingdom.png";
    if (code === "GD") return "/flags/Grenada.png";
    if (code === "GE") return "/flags/Georgia.png";
    if (code === "GF") return "/flags/French_Guiana.png";
    if (code === "GG") return "/flags/Guernsey.png";
    if (code === "GH") return "/flags/Ghana.png";
    if (code === "GI") return "/flags/Gibraltar.png";
    if (code === "GL") return "/flags/Greenland.png";
    if (code === "GM") return "/flags/Gambia.png";
    if (code === "GN") return "/flags/Guinea.png";
    if (code === "GP") return "/flags/Guadeloupe.png";
    if (code === "GQ") return "/flags/Equatorial_Guinea.png";
    if (code === "GR") return "/flags/Greece.png";
    if (code === "GS")
        return "/flags/South_Georgia_and_the_South_Sandwich_Islands.png";
    if (code === "GT") return "/flags/Guatemala.png";
    if (code === "GU") return "/flags/Guam.png";
    if (code === "GW") return "/flags/Guinea-Bissau.png";
    if (code === "GY") return "/flags/Guyana.png";
    if (code === "HK") return "/flags/Hong_Kong.png";
    if (code === "HM") return "/flags/Heard_Island_and_McDonald_Islands.png";
    if (code === "HN") return "/flags/Honduras.png";
    if (code === "HR") return "/flags/Croatia.png";
    if (code === "HT") return "/flags/Haiti.png";
    if (code === "HU") return "/flags/Hungary.png";
    if (code === "ID") return "/flags/Indonesia.png";
    if (code === "IE") return "/flags/Ireland.png";
    if (code === "IL") return "/flags/Israel.png";
    if (code === "IM") return "/flags/Isle_of_Man.png";
    if (code === "IN") return "/flags/India.png";
    if (code === "IO") return "/flags/British_Indian_Ocean_Territory.png";
    if (code === "IQ") return "/flags/Iraq.png";
    if (code === "IR") return "/flags/Iran.png";
    if (code === "IS") return "/flags/Iceland.png";
    if (code === "IT") return "/flags/Italy.png";
    if (code === "JE") return "/flags/Jersey.png";
    if (code === "JM") return "/flags/Jamaica.png";
    if (code === "JO") return "/flags/Jordan.png";
    if (code === "JP") return "/flags/Japan.png";
    if (code === "KE") return "/flags/Kenya.png";
    if (code === "KG") return "/flags/Kyrgyzstan.png";
    if (code === "KH") return "/flags/Cambodia.png";
    if (code === "KI") return "/flags/Kiribati.png";
    if (code === "KM") return "/flags/Comoros.png";
    if (code === "KN") return "/flags/Saint_Kitts_and_Nevis.png";
    if (code === "KP") return "/flags/North_Korea.png";
    if (code === "KR") return "/flags/South_Korea.png";
    if (code === "KW") return "/flags/Kuwait.png";
    if (code === "KY") return "/flags/Cayman_Islands.png";
    if (code === "KZ") return "/flags/Kazakhstan.png";
    if (code === "LA") return "/flags/Laos.png";
    if (code === "LB") return "/flags/Lebanon.png";
    if (code === "LC") return "/flags/Saint_Lucia.png";
    if (code === "LI") return "/flags/Liechtenstein.png";
    if (code === "LK") return "/flags/Sri_Lanka.png";
    if (code === "LR") return "/flags/Liberia.png";
    if (code === "LS") return "/flags/Lesotho.png";
    if (code === "LT") return "/flags/Lithuania.png";
    if (code === "LU") return "/flags/Luxembourg.png";
    if (code === "LV") return "/flags/Latvia.png";
    if (code === "LY") return "/flags/Libya.png";
    if (code === "MA") return "/flags/Morocco.png";
    if (code === "MC") return "/flags/Monaco.png";
    if (code === "MD") return "/flags/Moldova.png";
    if (code === "ME") return "/flags/Montenegro.png";
    if (code === "MF") return "/flags/Saint_Martin.png";
    if (code === "MG") return "/flags/Madagascar.png";
    if (code === "MH") return "/flags/Marshall_Islands.png";
    if (code === "MK") return "/flags/North_Macedonia.png";
    if (code === "ML") return "/flags/Mali.png";
    if (code === "MM") return "/flags/Myanmar.png";
    if (code === "MN") return "/flags/Mongolia.png";
    if (code === "MO") return "/flags/Macao.png";
    if (code === "MP") return "/flags/Northern_Mariana_Islands.png";
    if (code === "MQ") return "/flags/Martinique.png";
    if (code === "MR") return "/flags/Mauritania.png";
    if (code === "MS") return "/flags/Montserrat.png";
    if (code === "MT") return "/flags/Malta.png";
    if (code === "MU") return "/flags/Mauritius.png";
    if (code === "MV") return "/flags/Maldives.png";
    if (code === "MW") return "/flags/Malawi.png";
    if (code === "MX") return "/flags/Mexico.png";
    if (code === "MY") return "/flags/Malaysia.png";
    if (code === "MZ") return "/flags/Mozambique.png";
    if (code === "NA") return "/flags/Namibia.png";
    if (code === "NC") return "/flags/New_Caledonia.png";
    if (code === "NE") return "/flags/Niger.png";
    if (code === "NF") return "/flags/Norfolk_Island.png";
    if (code === "NG") return "/flags/Nigeria.png";
    if (code === "NI") return "/flags/Nicaragua.png";
    if (code === "NL") return "/flags/Netherlands.png";
    if (code === "NO") return "/flags/Norway.png";
    if (code === "NP") return "/flags/Nepal.png";
    if (code === "NR") return "/flags/Nauru.png";
    if (code === "NU") return "/flags/Niue.png";
    if (code === "NZ") return "/flags/New_Zealand.png";
    if (code === "OM") return "/flags/Oman.png";
    if (code === "PA") return "/flags/Panama.png";
    if (code === "PE") return "/flags/Peru.png";
    if (code === "PF") return "/flags/French_Polynesia.png";
    if (code === "PG") return "/flags/Papua_New_Guinea.png";
    if (code === "PH") return "/flags/Philippines.png";
    if (code === "PK") return "/flags/Pakistan.png";
    if (code === "PL") return "/flags/Poland.png";
    if (code === "PM") return "/flags/Saint_Pierre_and_Miquelon.png";
    if (code === "PN") return "/flags/Pitcairn_Islands.png";
    if (code === "PR") return "/flags/Puerto_Rico.png";
    if (code === "PS") return "/flags/Palestine.png";
    if (code === "PT") return "/flags/Portugal.png";
    if (code === "PW") return "/flags/Palau.png";
    if (code === "PY") return "/flags/Paraguay.png";
    if (code === "QA") return "/flags/Qatar.png";
    if (code === "RE") return "/flags/Réunion.png";
    if (code === "RO") return "/flags/Romania.png";
    if (code === "RS") return "/flags/Serbia.png";
    if (code === "RU") return "/flags/Russia.png";
    if (code === "RW") return "/flags/Rwanda.png";
    if (code === "SA") return "/flags/Saudi_Arabia.png";
    if (code === "SB") return "/flags/Solomon_Islands.png";
    if (code === "SC") return "/flags/Seychelles.png";
    if (code === "SD") return "/flags/Sudan.png";
    if (code === "SE") return "/flags/Sweden.png";
    if (code === "SG") return "/flags/Singapore.png";
    if (code === "SH") return "/flags/Saint_Helena.png";
    if (code === "SI") return "/flags/Slovenia.png";
    if (code === "SJ") return "/flags/Svalbard_and_Jan_Mayen.png";
    if (code === "SK") return "/flags/Slovakia.png";
    if (code === "SL") return "/flags/Sierra_Leone.png";
    if (code === "SM") return "/flags/San_Marino.png";
    if (code === "SN") return "/flags/Senegal.png";
    if (code === "SO") return "/flags/Somalia.png";
    if (code === "SR") return "/flags/Suriname.png";
    if (code === "SS") return "/flags/South_Sudan.png";
    if (code === "ST") return "/flags/São_Tomé_and_Príncipe.png";
    if (code === "SV") return "/flags/El_Salvador.png";
    if (code === "SX") return "/flags/Sint_Maarten.png";
    if (code === "SY") return "/flags/Syria.png";
    if (code === "SZ") return "/flags/Swaziland.png";
    if (code === "TC") return "/flags/Turks_and_Caicos_Islands.png";
    if (code === "TD") return "/flags/Chad.png";
    if (code === "TF") return "/flags/French_Southern_and_Antarctic_Lands.png";
    if (code === "TG") return "/flags/Togo.png";
    if (code === "TH") return "/flags/Thailand.png";
    if (code === "TJ") return "/flags/Tajikistan.png";
    if (code === "TK") return "/flags/Tokelau.png";
    if (code === "TL") return "/flags/East_Timor.png";
    if (code === "TM") return "/flags/Turkmenistan.png";
    if (code === "TN") return "/flags/Tunisia.png";
    if (code === "TO") return "/flags/Tonga.png";
    if (code === "TR") return "/flags/Turkey.png";
    if (code === "TT") return "/flags/Trinidad_and_Tobago.png";
    if (code === "TV") return "/flags/Tuvalu.png";
    if (code === "TW") return "/flags/Taiwan.png";
    if (code === "TZ") return "/flags/Tanzania.png";
    if (code === "UA") return "/flags/Ukraine.png";
    if (code === "UG") return "/flags/Uganda.png";
    if (code === "UM") return "/flags/United_States_Minor_Outlying_Islands.png";
    if (code === "US") return "/flags/United_States.png";
    if (code === "UY") return "/flags/Uruguay.png";
    if (code === "UZ") return "/flags/Uzbekistan.png";
    if (code === "VA") return "/flags/Vatican_City.png";
    if (code === "VC") return "/flags/Saint_Vincent_and_the_Grenadines.png";
    if (code === "VE") return "/flags/Venezuela.png";
    if (code === "VG") return "/flags/British_Virgin_Islands.png";
    if (code === "VI") return "/flags/U.S._Virgin_Islands.png";
    if (code === "VN") return "/flags/Vietnam.png";
    if (code === "VU") return "/flags/Vanuatu.png";
    if (code === "WF") return "/flags/Wallis_and_Futuna.png";
    if (code === "WS") return "/flags/Samoa.png";
    if (code === "XK") return "/flags/Kosovo.png";
    if (code === "YE") return "/flags/Yemen.png";
    if (code === "YT") return "/flags/Mayotte.png";
    if (code === "ZA") return "/flags/South_Africa.png";
    if (code === "ZM") return "/flags/Zambia.png";
    return "";
};

export { getFlags, getBanners };
