import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

export const TextField = ({
  label,
  name,
  leftIcon,
  type,
  secured = false,
  checkbox,
  checked,
  onChangeCheckbox,
  variant,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const handleShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-2 items-center">
        {checkbox && (
          <input
            type="checkbox"
            checked={checked}
            onChange={onChangeCheckbox}
          />
        )}
        <label
          htmlFor="dropdown"
          className="text-sm font-semibold text-black font-PtSans text-[14px]"
        >
          {label}
        </label>
      </div>
      <div
        className={`relative flex flex-row items-center gap-2 border ${
          variant === "primary" ? "border-primary" : "border-placeholder "
        } rounded-lg p-2`}
      >
        {leftIcon}
        <input
          className="w-full font-normal  text-[16px] focus:outline-none font-PtSans placeholder:text-placeholder"
          name={name}
          type={inputType}
          required
          {...rest}
        />
        {secured && (
          <a
            className="absolute top-[10px] right-2 text-xs"
            onClick={() => handleShowPassword()}
          >
            {inputType === "password" ? (
              <EyeIcon className="h-5 w-5 text-primary" />
            ) : (
              <EyeSlashIcon className="h-5 w-5 text-primary" />
            )}
          </a>
        )}
      </div>
    </div>
  );
};

export const DropDown = ({ data = [], checkbox, label, ...rest }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-2 items-center">
        {checkbox && <input type="checkbox" />}
        <label
          htmlFor="dropdown"
          className="text-sm font-semibold text-black font-PtSans text-[14px]"
        >
          {label}
        </label>
      </div>

      <div className="border border-primary rounded-lg p-2">
        <select
          name="dropdown"
          className="w-full text-[16px] focus:outline-none font-PtSans"
          {...rest}
        >
          {data.map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const Checkbox = (checked, onChangeCheckbox, label) => {
  return (
    <div className="flex gap-2 items-center">
      <input type="checkbox" checked={checked} onChange={onChangeCheckbox} />
      <label
        htmlFor="dropdown"
        className="text-sm font-semibold text-black font-PtSans text-[14px]"
      >
        {label}
      </label>
    </div>
  );
};
