import React, { useEffect, useState } from "react";
import { Button, TextField } from "../../../Components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Popup from "../../../Components/Popup";
import Memologo from "../../../Assets/logo";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/solid";
import { Checkbox } from "@material-tailwind/react";
import configSettings from "../../../config";
import useSession from "../../../Hooks/get-session";

export default function RegistrationView() {
  const { data: session } = useSession();

  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [popupDetail, setPopupDetail] = useState();

  const registerUser = async () => {
    if (!document.getElementById("termsAndConditions").checked) {
      setPopupDetail({
        type: "Warning",
        text: "Please agree with our terms and condistions first before signup",
      });

      setIsPopup(true);

      setTimeout(function () {
        setIsPopup(false);
      }, 4000);
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(configSettings.serverUrl + "/createUser", {
        email: user?.email,
        password: user?.password,
        conPassword: user?.confirm_password,
      });

      setIsLoading(false);

      setUser({ email: "", password: "", confirm_password: "" });

      setPopupDetail({
        type: "Success",
        text: "Your account has been successfully created",
      });

      setIsPopup(true);

      setTimeout(function () {
        setIsPopup(false);
        navigate("/login");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 400) {
        setPopupDetail({
          type: "Warning",
          text: error.response.data.message,
        });

        setIsPopup(true);

        setTimeout(function () {
          setIsPopup(false);
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (session) {
      navigate("/");
    }
  }, [session]);

  return (
    <>
      <div className="flex flex-col h-screen items-center justify-center bg-[#233654]">
        <Memologo />
        <div className="flex flex-col gap-3 mt-[35px] bg-white lg:p-[30px] md:w-[400px] lg:w-[400px] sm:w-[330px] sm:p-5 rounded-lg">
          <div className="flex flex-col gap-5 mt-4">
            <TextField
              label="Email"
              name="email"
              placeholder="Enter email"
              type="email"
              value={user.email}
              onChange={(e) =>
                setUser({
                  ...user,
                  email: e.target.value,
                })
              }
              leftIcon={<EnvelopeIcon className="h-5 w-5 text-primary" />}
            />
            <TextField
              label="Password"
              name="password"
              placeholder="Enter password"
              type="password"
              minLength={6}
              maxLength={12}
              value={user.password}
              onChange={(e) =>
                setUser({
                  ...user,
                  password: e.target.value,
                })
              }
              secured
              leftIcon={<KeyIcon className="h-5 w-5 text-primary" />}
            />

            <TextField
              label="Confirm Password"
              name="password"
              placeholder="Enter password"
              type="password"
              minLength={6}
              maxLength={12}
              value={user.confirm_password}
              onChange={(e) =>
                setUser({
                  ...user,
                  confirm_password: e.target.value,
                })
              }
              secured
              leftIcon={<KeyIcon className="h-5 w-5 text-primary" />}
            />
            <Checkbox
              color="blue"
              id="termsAndConditions"
              label={
                <p className="font-[500] text-black text-[14px]">
                  I agree to all <span className="text-primary">terms</span> and
                  <span className="text-primary"> conditions</span> *
                </p>
              }
            />

            <Button
              type="submit"
              title="Continue"
              loading={isLoading}
              onClick={registerUser}
            />
            <p className="text-sm text-center text-black font-light">
              Already have an account?{" "}
              <span className="text-primary">
                <Link to="/login">Log in</Link>
              </span>
            </p>
          </div>
        </div>
        <div className="mt-4 flex lg:w-[400px] md:w-[400px] sm:w-[330px] items-center justify-between">
          <p className="font-[500] text-[12px] text-white">© MapPro 2023</p>
          <div className="flex flex-row items-center gap-2 font-[500] text-[12px] text-white">
            <span>
              <EnvelopeIcon className="h-3 w-3" />
            </span>
            <p>andy@map-pro.com</p>
          </div>
        </div>
      </div>

      <Popup
        type={popupDetail?.type}
        text={popupDetail?.text}
        isPopup={isPopup}
      />
    </>
  );
}
