import React from "react";
import { Link } from "react-router-dom";

export default function UnknownView() {
  return (
    <div className="flex flex-col h-screen items-center justify-center">
      <div className="flex flex-col gap-4 text-center">
        <p className="text-[70px] font-bold text-gray-500">Oops!</p>
        <p className="text-lg font-normal uppercase">
          This page could not be found
        </p>
        <Link
          to="/"
          className="text-white bg-primary font-[500] text-[14px] p-2 rounded-lg mt-4"
        >
          Go To Map Pro
        </Link>
      </div>
    </div>
  );
}
